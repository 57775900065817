import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_BLOG } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/articleCategories/Editor';
import ArticlesList from 'Components/admin/articles/List';

export const TAB_DATA = 'data';
export const TAB_ARTICLES = 'articles';

export default class AdminArticleCategoryManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        articleCategory: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, articleCategory } = this.props;

        return (
            <StyledComponent
                className="admin-article-category-manage"
                styles={require('./styles')}
            >
                {articleCategory && (
                    <PageContent
                        breadcrumbs={[{
                            to: withVariables(ADMIN_BLOG.path, {}, { tab: 'articleCategories' }),
                            label: 'Lista kategorii',
                        }]}
                    >
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Edycja kategorii"
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={articleCategory.data}
                                    />
                                ),
                            }, {
                                key: TAB_ARTICLES,
                                label: 'Arykuły',
                                children: (
                                    <ArticlesList
                                        location={location}
                                        history={history}
                                        predefinedQuery={{
                                            articleCategoryId: articleCategory.data?.id
                                        }}
                                    />
                                ),
                            }]}
                        />
                    </PageContent>
                )}
            </StyledComponent>
        );
    }
}