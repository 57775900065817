import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    remove,
} from 'Redux/modules/admin/articleCategories/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            remove: dispatch(remove),
        },
    }),
)(Component);